/* yantramanav-100normal - latin */
@font-face {
  font-family: 'Yantramanav';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src:
    local('Yantramanav Thin '),
    local('Yantramanav-Thin'),
    url(/static/media/yantramanav-latin-100.73c72c4d.woff2) format('woff2'), 
    url(/static/media/yantramanav-latin-100.2ef4fa87.woff) format('woff'); /* Modern Browsers */
}

/* yantramanav-300normal - latin */
@font-face {
  font-family: 'Yantramanav';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('Yantramanav Light '),
    local('Yantramanav-Light'),
    url(/static/media/yantramanav-latin-300.909f4ffb.woff2) format('woff2'), 
    url(/static/media/yantramanav-latin-300.bb814361.woff) format('woff'); /* Modern Browsers */
}

/* yantramanav-400normal - latin */
@font-face {
  font-family: 'Yantramanav';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Yantramanav Regular '),
    local('Yantramanav-Regular'),
    url(/static/media/yantramanav-latin-400.3c7c417d.woff2) format('woff2'), 
    url(/static/media/yantramanav-latin-400.403d1be9.woff) format('woff'); /* Modern Browsers */
}

/* yantramanav-500normal - latin */
@font-face {
  font-family: 'Yantramanav';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src:
    local('Yantramanav Medium '),
    local('Yantramanav-Medium'),
    url(/static/media/yantramanav-latin-500.1ecc16f0.woff2) format('woff2'), 
    url(/static/media/yantramanav-latin-500.3df7f3f5.woff) format('woff'); /* Modern Browsers */
}

/* yantramanav-700normal - latin */
@font-face {
  font-family: 'Yantramanav';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Yantramanav Bold '),
    local('Yantramanav-Bold'),
    url(/static/media/yantramanav-latin-700.f0826a2b.woff2) format('woff2'), 
    url(/static/media/yantramanav-latin-700.d883507c.woff) format('woff'); /* Modern Browsers */
}

/* yantramanav-900normal - latin */
@font-face {
  font-family: 'Yantramanav';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src:
    local('Yantramanav Black '),
    local('Yantramanav-Black'),
    url(/static/media/yantramanav-latin-900.98376786.woff2) format('woff2'), 
    url(/static/media/yantramanav-latin-900.f3d51499.woff) format('woff'); /* Modern Browsers */
}


